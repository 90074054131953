html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-menu-item-selected {
  background: linear-gradient(to bottom, #6c63ff, transparent) !important;
}
.ant-menu-sub .ant-menu-submenu-selected > .ant-menu-submenu-title {
  background: linear-gradient(to right, #6c63ff, transparent) !important;
}

.btn-formula-active .ant-float-btn-body {
  background-color: #ffb663;
}

.item-highlighted {
  animation: 20s linear item-highlight forwards;
}

/* Select input shows all text of selected answers instead of hiding overflow */
.select-adaptive-height.ant-select {
  height: auto;
}
.select-adaptive-height .ant-select-selector {
  height: auto;
}
.select-adaptive-height .ant-select-selection-item {
  white-space: unset;
  line-height: 22px !important;
}

/* Select input options are shown in full instead of their overflow being hidden */
.ant-select-item-option-content {
  white-space: unset !important;
}

/* Necessary to control layout of item label in Forms */
.ant-form-item-label label {
  width: 100%;
}
.ant-space-vertical .ant-form-item-label label::after {
  display: none;
}

/* Changing color of chapter progress count */

.ant-progress-text > span{
  color: #021D40;
}

/* colorPrimary */
.ant-form-item-tooltip, .anticon-question-circle {
  color:#6C63FF !important; 
}

@keyframes item-highlight {
  from {
    box-shadow: 0 0 4px 4px orange;
  }
  to {
    box-shadow: none;
  }
}

.ant-input {
  scroll-margin-top: 250px;
}
.anticon svg {
  transition: transform 0.3s ease;
}

#backdrop {
  z-index: 10;
  pointer-events: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.1s;
}

#backdrop.active {
  transition: opacity 0s;
  animation: 1s linear backdrop-blink forwards;
}

@keyframes backdrop-blink {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}

.formula-drawer.shake {
  animation: 0.5s ease-in-out shake forwards 0.5s;
}

@keyframes shake {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(10px);
  }
  40% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* START Quill classes */
.template-highlight {
  background-color: yellow;
}
.page-break::after {
  content: '----- Saut de page -----';
  background-color: lightyellow;
  font-style: italic;
}
/* END Quill classes */

/* START antd forces use of global className */

.companyLogoInTable {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* END antd forces use of global className */
